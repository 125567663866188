@import url('https://fonts.googleapis.com/css2?family=Krona+One&family=Montserrat&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.cdnfonts.com/css/tex-gyre-adventor');

:root{
  --acc: #0099ff;

  --ok: 46, 184, 46;
  --warning: 247, 167, 20;
  --danger: 218, 69, 69;

  --main-font: /* "TeXGyreAdventor", */ "Raleway";
  --heading-font: "Krona One";
}

@media (prefers-color-scheme: dark){
  :root{
      --bg: #222;
      --bg1: #333;
      --bg2: #444;
      --fg: #eee;
      --bas: hsl(39, 24%, 73%);
  }
  .custom-icon{
    filter: saturate(0) brightness(10);
  }
}
@media (prefers-color-scheme: light){
  :root{
      --bg: white;
      --bg1: #eee;
      --bg2: #aaa;
      --fg: black;
      --bas: hsl(0, 0%, 88%);
  }
  .custom-icon{
    filter: saturate(0) brightness(10) invert(1);
  }
}

/*****************UNIVERSALS****************/
*{
  transition-duration: 0.3s;
}
.App{
  min-height: 100vh;
}
body, .App{
  font-family: var(--main-font);
  margin: 0;
  display: flex; flex-direction: column; align-items: stretch;
  overflow-x: hidden;
  background-color: var(--bg); color: var(--fg);
}
.mobile-only{
  display: none;
}
h1, h2, h3, h4, h5{
  font-family: var(--heading-font);
  font-weight: normal;
}
h2{
  color: var(--acc);
  font-size: 1.35em;
  text-align: center;
}
.framed{
  border-radius: 1em;
  border: 3px solid var(--bg2);
  /* margin: 1em 0; */
  padding: 1em;
}
.framed:hover{
  border-color: var(--fg);
}

p{
  margin-top: 0.5em;
}
small{
  font-family: var(--main-font);
  font-weight: bold;
}
.circle{
  border-radius: 100%;
}
div:not(.App), form{
  border-radius: 1em;
}
.logo{
  height: 5em;
  margin: 0.5em;
}
.accent{
  color: var(--acc);
}
.justify{
  text-align: justify;
}
.print-only{ display: none; }
.grid-2, .grid-3{ display: grid; gap: 1em; }
.grid-2{ grid-template-columns: 1fr 1fr; }
.grid-3{ grid-template-columns: 1fr 1fr 1fr; }

.flex-down{
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
.flex-right{
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}
.center{
  justify-content: center;
  align-items: center;
  text-align: center;
}
.center-vert{
  align-items: center;
}
.stretch>div, .stretch>a{
  flex: 1;
}
.tight, .tight p, .tight h1, .tight h2, .tight h3, .tight ul, .tight ol{
  gap: 0.2em;
  margin: 0;
}
.wrap{
  flex-wrap: wrap;
}

.centered-margins{
  --padding-size: 5em;
  margin-left: auto; margin-right: auto;
  width: fit-content;
  padding-left: var(--padding-size); padding-right: var(--padding-size);
}

.hover-lift:hover{
  color: inherit;
  box-shadow: 0 0 1rem #00000044;
}
.hover-light:hover{
  color: inherit;
  box-shadow: 0 0 1em inset var(--acc);
}

.hidden{
  opacity: 0;
}
.large{
  font-size: 3rem;
}

/* UNI COLORS */
.ghost{
  opacity: 0.4;
}
.grayed-out{
  font-style: italic;
  color: gray;
  grid-column: 1 / span 2;
}
.grayed-out::before, .grayed-out::after{
  content: " — ";
}
.currently{
  color: var(--acc);
  font-weight: bold;
}

/* CLICKABLE */
a{
  text-decoration: none;
  color: inherit;
}
a:hover, .clickable:hover{
  color: var(--acc);
}
.clickable{
  user-select: none;
  cursor: pointer;
}

#main-wrapper{
  width: 90vw;
  margin: 0 auto;
  padding: 2em;
  flex: 1 0 auto;
}

.custom-icon{
  height: 2em;
}

.four-oh-four{
  font-size: min(40vh, 30vw);
  margin: 0;
  text-align: center;
  opacity: 0.2;
}

@media screen and (max-width: 600px) {
  .mobile-hide{
    display: none;
  }
  .mobile-only{
    display: initial;
  }
  body{
    padding-bottom: 0;
  }

  .but-mobile-down{
    display: flex !important;
    flex-direction: column !important;
  }

  #main-wrapper{
    width: auto;
  }

  .grid-2, .grid-3{
    gap: 0.5em;
  }
}

@media print{
  *{
    font-size: 10px;
  }
  .print-only{
    display: initial !important;
  }
  .print-hide{
    display: none !important;
  }
  .but-print-flex-down{
    display: flex; flex-direction: column;
  }
  .but-print-flex-right{
    display: flex; flex-direction: row;
  }
  .but-print-left{
    text-align: left;
    justify-content: left;
  }
  .but-print-grid-2{
    display: grid; grid-template-columns: repeat(2, 1fr);
  }
  .click-tile{
    display: none;
  }
  h1{
    font-size: 1.5em !important;
  }
  h2{
    font-size: 1.25em !important;
  }

  header{
    padding: 1em 4em !important;
  }
  header h1{
    font-size: 2.5em !important;
  }
  header h2{
    font-size: 1.5em !important;
    color: var(--fg) !important;
    background-color: var(--bg1);
    border-radius: 0.5em;
    padding: 3px;
  }
  header img{
    height: 6em !important;
  }
  #main-wrapper{
    padding: 0.5em 2em;
  }
  footer{
    padding: 0.5em 5em !important;
  }
}
