.text-box{
    background-color: var(--bg1);
    padding: 1em;
    position: relative;
}
.text-box:not(.no-highlight):hover{
    background-color: var(--bg2);
}
.text-box p{
    text-align: center;
}
.text-box.pin-left h2{
    text-align: left;
}
.text-box.pin-left p{
    text-align: left;
}
.text-box h3{
    font-family: var(--main-font);
    font-weight: bold;
}
.text-box p:first-child{
    font-style: italic;
}
.text-box .ghost{
    opacity: 0.7;
}
.text-box.flex-down:not(.pin-left){
    justify-content: space-between;
}

.top-right{
    position: absolute;
    top: 1em;
    right: 1em;
    opacity: 0.5;
}

@media print{
    .text-box{
        padding: 0.5em 1em;
    }
    .text-box.horizontal{
        display: grid;
        align-items: center;
        grid-template-columns: 3em 1fr 2fr 0;
        gap: 0.5em;
    }
    .text-box.horizontal h2, .text-box.text-box.horizontal p{
        text-align: left;
    }
    .text-box.horizontal a{
        text-align: right;
    }
}