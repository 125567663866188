.zoom-icons i:hover, .zoom-icons img:hover{
    scale: 150%;
}

.tech-grid{
    align-items: end;
    flex-wrap: wrap;
    --width: 2rem;
}
.tech-grid .container{
    align-items: center;
    justify-content: flex-end;
}
.tech-grid-bar{
    width: var(--width);
    background-color: var(--fg);
    border-radius: 5px !important;
    opacity: 0.5;
}
.tech-grid-bar:hover{
    opacity: 1;
}

.tech-grid img{
    height: var(--width);
}
.tech-grid i{
    font-size: var(--width);
}  

@media print {
    .tech-grid {
        --width: 1.5rem;
    }
}