.click-tile{
    background-color: var(--bg1);
    padding: 0.5em 1em;
    border: 3px solid var(--acc);
}
.click-tile:hover{
    background-color: var(--acc);
    color: var(--fg) !important;
}
.click-tile i{ font-size: 5em; }
.click-tile.small i{ font-size: 1.5em; }
.click-tile h3{
    margin: 0;
}

.see-also{
    margin: 0.5em auto;
}
.see-also:last-child{
    margin-top: 1em;
}
.see-also h3{
    margin: 0;
}

.click-tile .custom-icon{
    height: 1.5em;
}

@media screen and (max-width: 600px) {
    .see-also{
        padding: 1em;
    }
}
