header{
    background: var(--bg2);
    padding: 2em 4em;
}
header h1{
    font-size: 4em;
}
header h1, header h2{
    margin: 0;
}
header img{
    height: 10em;
}

/**************************/

footer{
    background: #111;
    color: white;
    padding: 1em 5em;
    flex-shrink: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    font-size: 85%;
    text-align: right;
}
footer>div:first-child{
    flex-direction: row-reverse;
}
footer .logo{
    height: 5em;
}
footer h2, footer p{
    margin: 0;
}
#end-bar{
    align-items: end;
    white-space: nowrap;
    gap: 0;
}
#lang{
    width: fit-content;
}
#rodo {
    text-align: left;
    font-style: italic;
    width: 40em;
}
#footer-links{
    display: flex;
    flex-direction: column;
    text-align: left;
    flex-wrap: wrap; column-gap: 1em;
    max-height: 5em;
}

/*********************/

.section-header{
    align-items: flex-start;
    background-color: var(--bg1);
    padding: 0.5em 1em;
}
.section-header h1, .section-header h2, .section-header h3{
    margin: 0;
    text-align: center;
}
.section-header img{
    height: 1.2em;
}
.section-header>div{
    display: flex;
    flex-direction: row;
    text-align: right;
    align-items: flex-end;
}

/***********************/

@media screen and (max-width: 600px) {
    header h1{
        margin: 0;
        font-size: 2em;
    }
    header img{
        height: 7em;
    }

    footer{
        flex-direction: column;
        position: initial;
        gap: 0.5em;
        padding: 1em; width: calc(100vw - 2*1em);
        text-align: center;
    }
    #end-bar{
        align-items: center;
    }
    #footer-links{
        max-height: none;
        text-align: center;
    }
}

@media print {
    footer {
        position: fixed;
        bottom: 0; left: 0; right: 0;
    }
    #footer-links {
        width: 25em;
    }
}