.timeline{
    display: grid;
    grid-template-columns: 1fr 3em 1fr;
    gap: 0.5em;
}

.timeline .boxes>h3:first-child{
    text-align: center;
    margin: 0;
}

.timeline .line{
    position: relative;
}
#tmln-line-itself{
    background: var(--fg);
    opacity: 0.5;
    height: 100%;
    width: 5px;
    margin: 0 auto;
}
#tmln-line-years,
#tmln-line-bars{
    position: absolute; top: 0; left: 0;
    height: 100%; width: 100%;
}
#tmln-line-years{
    display: flex;
    flex-direction: column;
    justify-content: space-between; align-items: center;
    height: calc(100% + 1em); top: -0.5em;
    pointer-events: none;

    & span {
        font-family: "Montserrat";
        font-size: 0.75em;
        background-color: var(--bg);
        width: 100%;
        --padding-size: 0em;
        padding-block: var(--padding-size);
        translate: 0 calc(-1 * var(--padding-size));
    }
}
#tmln-line-bars{
    display: grid;
    grid-auto-flow: column;
    gap: 1px;
}
#tmln-line-bars span{
    /* background: var(--bg2); */
    /* opacity: 0.5; */
    border-radius: 0.5em;
}
#tmln-line-bars span.ev{
    background: var(--acc);
}

@media screen and (max-width: 600px){
    .timeline .line{
        display: none;
    }
}